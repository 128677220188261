<template>
<div class="searchListMain">
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <!--搜索-->
    <div class="wCenter" v-if="config.c1&&config.c1.isShow">
        <div class="searchMain">
            <p class="title">搜索</p>
            <Form :model="form" :label-width="0">
                <FormItem class="product">
                    <Input v-model="form.keyword" placeholder="请输入要搜索的内容" clearable @keydown.enter.native.prevent="search"></Input>
                </FormItem>
                <FormItem class="submit">
                    <Button type="primary" @click="search()">搜索</Button>
                </FormItem>
            </Form>
            <div class="textListt">
                <span class="fb">热搜词:</span>
                <div>
                    <span v-for="(item,index) in hotKeyList" :key="index" @click="searchByKeyword(item.keyword)">{{item.keyword}}</span>
                </div>
            </div>
        </div>
        <p class="searchCount" v-if="showSearch">已搜索 {{total}} 条信息</p>
    </div>
    <!--搜索-->
    <!--新闻列表-->
    <div class="wCenter">
        <div class="searchList" v-if="config.c1&&config.c1.isShow&&dataList && dataList.length>0">
            <div class="searchListItem" v-for="(item,index) in dataList" :key="index" @click="goDetail(item.type,item.id)">
                <p class="title"><span>[ {{item.titleLabel}} ]</span>{{item.title}}</p>
                <p class="time">{{item.time}}</p>
                <p class="content" v-html="item.content"></p>
            </div>
        </div>
        <div v-else class="noData">
            <img src="../../assets/null.png" />
        </div>
    </div>

    <div class="tabFoot" v-if="config.c1&&config.c1.isShow">
        <Page :current="searchForm.pageNumber" :total="total" :page-size="searchForm.pageSize" @on-change="changePage" @on-page-size-change="changePageSize" :page-size-opts="[10,20,50]" size="small" show-total show-elevator show-sizer></Page>
    </div>
    <!--新闻列表-->

</div>
</template>

<script>
import util from "@/libs/util.js";
import {
    getPageConfigByCode,
    getNewsList,
    getHotKeyList
} from '@/api/api'
import TopImg from '@/components/topImg'
export default {
    name: 'searchList',
    components: {
        TopImg,
    },
    data() {
        return {
            config: {},
            form: {
                keyword: '',
            },
            dataList: '',
            hotKeyList: '',
            searchForm: {
                pageNumber: 1,
                pageSize: 10,
                keyword: '',
            },
            total: 0,
			showSearch:false
        }
    },
    computed: {},
    mounted() {

    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getPageConfigByCode()

        },
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'search/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                    let c = this.config.c1
                    if (c && c.isShow) {
                        this.getDataList();
                        this.getHotKeyList();
                    }
                }
            })
        },
        changePage(v) {
            this.searchForm.pageNumber = v;
            this.getDataList();
        },
        changePageSize(v) {
            this.searchForm.pageSize = v;
            this.getDataList();
        },
        //获取新闻列表
        getDataList() {
            getNewsList(this.searchForm).then(res => {
                if (res.success) {
                    this.dataList = res.result.records;
                    this.total = res.result.total;
                }
            })
        },
        //获取热搜词列表
        getHotKeyList() {
            getHotKeyList().then(res => {
                if (res.success) {
                    this.hotKeyList = res.result;
                }
            })
        },
        //热搜词搜索
        searchByKeyword(e) {
            this.searchForm.keyword = e;
            getNewsList(this.searchForm).then(res => {
                if (res.success) {
                    this.dataList = res.result.records;
                    this.total = res.result.total;
                }
            })
        },
        //搜索
        search() {
            this.searchForm.keyword = this.form.keyword;
			this.showSearch=true;
            getNewsList(this.searchForm).then(res => {
                if (res.success) {
                    this.dataList = res.result.records;
                    this.total = res.result.total;
                }
            })
        },
        //新闻详情
        goDetail(type, id) {
            if (type == 1) {
                util.pageChange(this.$router, `/exhibitionDetail`, {
                    id: id
                }, '_self');

            } else {
                util.pageChange(this.$router, `/newsDetail`, {
                    id: id
                }, '_self');

            }

        }
    },
}
</script>

<style lang="scss" scoped>
.searchMain {
    background: #eeeeee;
    padding: 0.4rem 0.5rem;
    margin-top: 0.4rem;

    @media (max-width:767px) {
        margin-top: .3rem;
    }

    .title {
        color: #333333;
        font-size: 0.24rem;
        margin-bottom: 0.3rem;
		@media (max-width:767px) {
			font-size: 0.4rem;
		}

    }

    .ivu-form {
        display: flex;
        flex-wrap: wrap;
    }

    .product {
        width: 10.7rem;
    }

    .submit {
        width: 1rem;
        margin-left: 0.3rem;

        @media (max-width: 767px) {
            width: 50%;
            margin-left: 25%;
        }

        .ivu-btn-primary {
            width: 1rem;
            height: 0.5rem;
            background-color: #333333;
            border-color: #333333;

            @media (max-width: 767px) {
                width: 100%;
                height: 0.5rem * 1.5;
            }
        }
    }

    .textListt {
        display: flex;
        flex-wrap: wrap;

        div {
            flex: 0 0 10rem;
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 767px) {
                flex: 0 0 100%;
            }
        }

        @media (max-width: 767px) {
            margin-top: 0.2rem;
			
        }

        span {
            color: #727272;
            font-size: 0.24rem;
            margin-right: 0.6rem;
            margin-bottom: 0.1rem;
            cursor: pointer;

            @media (max-width: 767px) {
                margin-right: 0.4rem;
				font-size: 0.36rem;
            }
        }
    }
}

.searchCount {
    color: #727272;
    font-size: 0.24rem;
    margin: 0.3rem 0 0;

    @media (max-width: 767px) {
        margin: 0.2rem 0 0;
		font-size: 0.38rem;
    }
}

.searchList {
    .searchListItem {
        border-bottom: 0.01rem solid #eeeeee;
        padding: 0.2rem 0 .1rem;
        cursor: pointer;
		@media (max-width: 767px) {
			padding: 0.3rem 0 .3rem;
		}
        .title {
            color: #333333;
            font-size: 0.24rem;
            border-left: 0.07rem solid #ff1932;
            padding-left: 0.25rem;
            margin-top: 0;
            font-weight: bold;
			@media (max-width: 767px) {
				font-size: 0.4rem;
			}
            span {
                color: #ff1932;
                padding: 0 0.1rem;
            }
        }

        .time {
            color: #aaaaaa;
            font-size: 0.2rem;
            margin: 0.1rem 0;
            padding-left: 0.34rem;

            @media (max-width: 767px) {
                margin: 0.1rem 0;
				font-size: 0.36rem;
            }
        }

        .content {
            color: #333333;
            font-size: 0.2rem;
            padding-left: 0.34rem;
            overflow: hidden;
            /*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
            display: -webkit-box;
            line-height: 2;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
			@media (max-width: 767px) {
				font-size: 0.38rem;
			}
        }
    }
}

.tabFoot {
    text-align: center;
    border-top: 0;
    margin-top: .5rem;
    overflow: hidden;
}

.noData {

    text-align: center;
    margin: 0.4rem 0;

    img {
        width: 5rem;
    }
}
</style>
<style lang="scss" scoped>
.searchMain {
    ::v-deep .ivu-input {
        height: 0.5rem !important;

        @media (max-width: 767px) {
            height: 0.5rem * 1.5 !important;
        }
    }

    ::v-deep .ivu-input-icon {
        line-height: 0.5rem;
        font-size: 0.14rem;

        @media (max-width: 767px) {
            line-height: 32px;
            font-size: 16px;
        }
    }
}
</style>
